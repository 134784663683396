<template>
  <Spinner v-if="files === null" />
  <div v-else>
    <div :class="$style.nav" class="flex-row flex-hcenter">
      <h5 class="mb-1">Meine Dateien</h5>
      <router-link to="/my/files">Neueste</router-link>
      <router-link to="/my/files/all">Alle</router-link>
    </div>

    <UploadedFileList v-model="files" :showAll="showAll"/>
  </div>
</template>

<script>
import { actions, mutations, pages } from "@/constants.js";
import Spinner from "@/components/Spinner.vue";
import UploadedFileList from "@/components/UploadedFileList.vue";

export default {
  name: "MyFiles",
  props: {
    showAll: {
      type: Boolean,
      default: false
    }
  },
  computed: {
		isLoggedIn() {
			return this.$store.getters.isLoggedIn;
		},
    files: {
      get() {
        return this.$store.state.uploadedFiles;
      },
      set(value) {
        this.$store.commit(mutations.SET_UPLOADED_FILES, value);
      }
    }
  },
  watch: {
    isLoggedIn(newValue) {
      // Logged in state is determined asycronically, so we cannot redirect in mounted
      if (newValue == false) this.$router.push(pages.LOGIN);
    }
  },
	mounted() {
    if (this.files === null) this.$store.dispatch(actions.GET_FILES);
	},
  components: {
    Spinner,
    UploadedFileList
  }
};
</script>

<style module type="scss">
  .nav > a {
    margin-left: 1rem;
    margin-top: 8px;
    color: #757575;
  }
</style>
